<template>
  <div
    class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  >
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/status"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">
           {{$t('new_status')}}
          </div>
          <div v-else class="text-2xl font-bold text-green-500 ">{{$t('edit_status')}}</div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6">
          <div class="space-y-4 px-10">
            <div
              class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1"
                    >{{$t('name')}} <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p  v-if="formData._id" class="py-2 text-xs px-2 outline-none block h-full w-full" >
                {{formData.name}}
              </p>
              <p v-else >
                <input
                  id="name"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.name"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
              
            </div>

            <div
              class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="fees" class="bg-white text-gray-700 px-1"
                    >{{$t('fees')}} <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <input
                  id="fees"
                  autocomplete="false"
                  tabindex="0"
                  type="text"
                  v-model="formData.fees"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>

            <div
              class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="color" class="bg-white text-gray-700 px-1"
                    >{{$t('color')}} <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <label for="colorPicker">
                  <input
                    type="color"
                    value="formData.color"
                    v-model="formData.color"
                    id="colorPicker"
                  />
                </label>
              </p>
            </div>
          </div>

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              @click="save"
            >
              {{$t('save')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- ******************* Partie Script *********************** -->
<script>
export default {
  data() {
    return {
      formData: {},
    };
  },
  async mounted() {
    await this.dataToEdit();
    await this.initColorPicker();
  },
  methods: {
    async initColorPicker() {
      document.querySelectorAll("input[type=color]").forEach(function (picker) {
        var targetLabel = document.querySelector(
            'label[for="' + picker.id + '"]'
          ),
          codeArea = document.createElement("span");

        codeArea.innerHTML = picker.value;
        targetLabel.appendChild(codeArea);

        picker.addEventListener("change", function () {
          codeArea.innerHTML = picker.value;
          targetLabel.appendChild(codeArea);
        });
      });
    },
    async save() {
      if (this.formData._id) {
        const data = await this.$server.update("status", this.formData);
        if (data._id) {
          alert(this.$t('status_updated'), "success");
        }
      } else {
        if (this.checkData()) {
          const data = await this.$server.create("status", this.formData);
          //console.log(this.formData);
          if (data._id) {
            this.formData = {};
            alert(this.$t('status_created'), "success");
          }
        } else {
          alert(this.$t('all_required_field'), "warning");
        }
      }
    },
    checkData() {
      if (this.formData.name) return true;
      else return false;
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("status", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        //console.log(this.formData)
      }
    },
  },
};
</script>

<!-- ******************* Partie style *********************** -->
<style >
[type="color"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  width: 15px;
  height: 15px;
  border: none;
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border: none;
}

.color-picker {
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #ccc;
  background-color: #f8f9f9;
}
</style>